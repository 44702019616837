<template>
    <div>
        <van-nav-bar
                placeholder
                fixed
                left-arrow
                :border='false'
                class="header"
                title="书评详情"
                @click-left="$router.go(-1)">
            <template #left>
                <van-icon name="arrow-left" size="25" color="#0A1C33"/>
            </template>
        </van-nav-bar>
        <div class="review-book-top">
            <div style="width: 100%">
                <div class="review-book-top-left">
                    <div class="review-book-top-left">
                        <p>
                            <img :src="listList.avatar" alt="" class="book_img1">
                        </p>
                        <p class="review-book-title">{{listList.nickname}}</p>
                    </div>
                    <div>
                        <span class="review-book-subtitle" v-if="listList.star == 1">不好</span>
                        <span class="review-book-subtitle" v-if="listList.star == 2">一般</span>
                        <span class="review-book-subtitle" v-if="listList.star == 3">还行</span>
                        <span class="review-book-subtitle" v-if="listList.star == 4">不错</span>
                        <span class="review-book-subtitle" v-if="listList.star == 5">力荐</span>
                        <van-rate v-model="listList.star" :icon="img" :void-icon="img1" size="13" readonly/>
                    </div>
                </div>
                <p class="sentiment-text">
                    {{listList.content}}
                </p>
                <div class="support">
                    <p class="support-time">{{listList.created_at}}</p>
                    <p class="support-number"
                       @click="do_likes(listList)"
                       :class="listList.is_admire == 1?'text_active':''">
                        <img src="../../assets/images/icon1.png" alt="" class="icon-img"
                             v-if="listList.is_admire == 1">
                        <img src="../../assets/images/icon.png" alt="" class="icon-img" v-else>
                        {{listList.likes}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BookDetails",
        data() {
            return {
                img: require("../../assets/images/Stars_icon.png"),
                img1: require("../../assets/images/Stars_icon_50.png"),
                id: this.$route.query.id,
                listList: [],
            }
        },
        created() {
            this.detail()
        },
        methods: {
            // 点赞
            do_likes(item) {
                if (item.is_admire == 0) {
                    this.$axios.post('/api/comment/do_likes', {
                        comment_id: item.id
                    })
                        .then(res => {
                            if (res.data.code == 200) {
                                this.$toast(res.data.message);
                                this.detail()
                            }
                        })
                        .catch(err => {
                            this.$toast(err.response.data.message);
                        })
                } else {
                    this.$axios.post('/api/comment/cancel_likes', {
                        comment_id: item.id
                    })
                        .then(res => {
                            if (res.data.code == 200) {
                                this.$toast(res.data.message);
                                this.detail()
                            }
                        })
                        .catch(err => {
                            this.$toast(err.response.data.message);
                        })
                }

            },


            detail() {
                this.$axios.get('/api/comment/detail', {
                    params: {
                        comment_id: this.id
                    }
                })
                    .then(res => {
                        this.listList = res.data.data
                    })
                    .catch(err => {
                        this.$toast(err.response.data.message);
                    })
            }
        },
    }
</script>

<style scoped>
    .text_active {
        color: #4D77FD !important;
    }

    .support-number {
        color: #BFC2CC;
        font-size: 20px;
        display: flex;
        align-items: center;
    }

    .support-time {
        color: #BFC2CC;
        font-size: 20px;
    }

    .support {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;
    }

    .icon-img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }

    .sentiment-text {
        font-size: 26px;
        font-weight: 400;
        color: rgba(96, 98, 102, 1);
        line-height: 42px;
        margin-top: 28px;
    }

    .review-book-subtitle {
        color: #BFC2CC;
        font-size: 24px;
    }

    .review-book-title {
        color: #98664A;
        font-size: 24px;
        width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .review-book-top-left {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
    }

    .review-book-top {
        display: flex;
        padding: 0 40px 20px;
        margin-top: 40px;
    }

    .book_img1 {
        width: 60px;
        height: 60px;
        border-radius: 100%;
        margin-right: 10px;
    }

    .Stars_icon {
        width: 21px;
        height: 21px;
        margin-left: 10px;
    }
</style>